<template>
  <v-container>
    <v-row>
      <v-col md="6">
        <UserForm />
      </v-col>

      <v-col md="6">
        <h3>Elenco Utenti</h3>
        <v-text-field
          label="Cerca"
          v-model="search"
          type="text"
          class="form-control mb-5"
          name="search"
          hide-details
        ></v-text-field>
        <v-data-table
          :options.sync="tableParams"
          :server-items-length="totalItems"
          :loading="tableLoading"
          :headers="headers"
          :items="users"
          sort-by="email"
          @update:must-sort="getDataFromApi"
          @update:items-per-page="getDataFromApi"
          @update:page="getDataFromApi"
          @update:sort-by="getDataFromApi"
          @update:sort-desc="getDataFromApi"
          class="elevation-1"
        >
          <template v-slot:item.password="{ item }">
            <PasswordButton :password="item.password" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import _ from "lodash";
import authMixin from "../mixins/auth.mixin";

import UserForm from "../components/users/UserForm";
import PasswordButton from "../components/users/PasswordButton";
export default {
  mixins: [authMixin],
  components: { UserForm, PasswordButton },
  data() {
    return {
      updateUsers: null,
      usersLoading: false,
      shareWithModal: false,
      headers: [
        { text: "Email", value: "email" },
        { text: "Password", value: "password", sortable: false },
      ],
    };
  },
  watch: {
    options: {
      async handler() {
        await this.getDataFromApi();
      },
      deep: true,
    },
    search: _.debounce(async function (val) {
      val && (await this.getDataFromApi());
    }, 500),
  },
  computed: {
    tableParams: {
      get() {
        return this.$store.state.usersView.tableParams;
      },
      set(value) {
        this.$store.commit("usersView/setTableParams", value);
      },
    },

    tableLoading: {
      get() {
        return this.$store.state.usersView.tableLoading;
      },
      set(value) {
        this.$store.commit("usersView/setTableLoading", value);
      },
    },

    search: {
      get() {
        return this.$store.state.usersView.search;
      },
      set(value) {
        this.$store.commit("usersView/setSearch", value);
      },
    },

    totalItems() {
      return this.$store.state.usersView.users.totalItems;
    },
    users() {
      return this.$store.getters["usersView/users/list"];
    },
  },
  async mounted() {
    await this.getDataFromApi();
  },

  methods: {
    onRemoveClick(itemId) {
      this.$store.commit("usersView/users/setCurrentId", itemId);
      this.$store.commit("usersView/setRemoveUserDialog", true);
    },

    async getDataFromApi() {
      await this.$store.dispatch("usersView/loadTableData");
    },
  },
};
</script>

<style>
</style>
