<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          x-small
          fab
          depressed
          color="primary"
          @click.prevent="visible = !visible"
          v-bind="attrs"
          v-on="on"
          ><v-icon v-text="!visible ? 'fa-eye' : 'fa-eye-slash'"></v-icon
        ></v-btn>
      </template>
      <span>{{ !visible ? "Mostra password" : "Nascondi password" }}</span>
    </v-tooltip>
    <span v-if="visible" class="ml-2">{{ password }}</span>
  </div>
</template>

<script>
export default {
  props: {
    password: String,
  },
  data() {
    return {
      visible: false,
    };
  },
};
</script>

<style>
</style>