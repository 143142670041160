<template>
  <validation-observer ref="observer">
    <v-form class="mb-3" autocomplete="off">
      <h3>Crea utente</h3>
      <v-row>
        <v-col cols="12" sm="6">
          <validation-provider
            v-slot="{ errors }"
            name="email"
            rules="required|email|unique"
          >
            <v-text-field
              label="Email"
              v-model="email"
              type="text"
              class="form-control"
              name="email"
              hide-details
            ></v-text-field>
            <div
              v-for="error in errors"
              :key="error"
              class="alert alert-danger red--text"
              role="alert"
            >
              {{ error }}
            </div>
          </validation-provider>
        </v-col>
        <v-col cols="12" sm="6">
          <validation-provider
            v-slot="{ errors }"
            name="password"
            rules="required|min:8"
          >
            <v-text-field
              label="password"
              v-model="password"
              :append-icon="showPassword ? 'fa-eye' : 'fa-eye-slash'"
              @click:append="() => (showPassword = !showPassword)"
              :type="showPassword ? 'password' : 'text'"
              class="form-control"
              name="password"
              hide-details
            ></v-text-field>
            <div
              v-for="error in errors"
              :key="error"
              class="alert alert-danger red--text"
              role="alert"
            >
              {{ error }}
            </div>
          </validation-provider>
        </v-col>
        <v-col>
          <v-btn
            color="secondary"
            @click.prevent="onGeneratePasswordClick"
            class="mr-1"
            >Genera password</v-btn
          >
          <v-btn color="primary" @click.prevent="onSaveUserClick" class="mr-1"
            >Salva</v-btn
          >
        </v-col>
      </v-row>
    </v-form>
  </validation-observer>
</template>

<script>
import _ from "lodash";
import { required, email, min } from "vee-validate/dist/rules";
import {
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
  extend,
} from "vee-validate";
import authMixin from "../../mixins/auth.mixin";
import UserService from "../../services/user.service";

setInteractionMode("eager");

extend("required", required);
extend("min", min);
extend("email", email);

// The messages getter may also accept a third parameter that includes the data we returned earlier.
extend("unique", {
  validate: async (value) => {
    const res = await UserService.search(value);
    // Notice that we return an object containing both a valid property and a data property.
    return {
      valid: res.data?.length == 0,
    };
  },
  message: () => {
    return "Email già registrata";
  },
});

export default {
  mixins: [authMixin],
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      email: null,
      password: null,
      showPassword: false,
    };
  },
  methods: {
    async onSaveUserClick() {
      let isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      await this.$store.dispatch("usersView/save", {
        email: this.email,
        password: this.password,
        id: this.user?.id,
      });
      this.id = null;
      this.email = null;
      this.password = null;
      // Wait until the models are updated in the UI
      this.$nextTick(() => {
        this.$refs.observer.reset();
      });
    },
    async onGeneratePasswordClick() {
      const res = await UserService.generatePassword();
      this.password = res.data;
    },
  },
  computed: {
    user() {
      return this.$store.getters["usersView/users/current"];
    },
  },
};
</script>

<style>
</style>
